/* eslint-disable max-lines */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { UserData } from "app/pages/home/home.model";
import { ConversationService } from "app/services/conversation.service";
import { MarkdownService } from "ngx-markdown";
import { Subject, Subscription, catchError, map, mergeMap, switchMap, throwError } from "rxjs";
import { Conversation } from "./api-models/conversation.models";
import { AppStore } from "./app-store.service";
import { InternetSearchStatus } from "./app.model";
import { LS_TOKEN, LS_USER_GEO, LS_USER_ID } from "./const/app-constant";
import { HttpClientService } from "./core/modules/http-client/http-client.service";
import { environment } from "./environments/environment";
import { Brain } from "./pages/dashboard/dashboard.model";
import { BrainLLM, ChatDataRole, ChatQueryResponse, defaultBrainLLM, GetAssistantResponse } from "./pages/my-brain/my-brain.model";

@Injectable({
  providedIn: "root",
})
export class AppService {

  allowBrainLLMSelect = environment.featureFlags.allowBrainLLMSelect;
  constructor(
    private http: HttpClientService,
    private https: HttpClient,
    private conversationService: ConversationService,
    private translocoService: TranslocoService,
    private markdownService: MarkdownService,
  ) {}

  public dashboardSubject: Subject<boolean> = new Subject<boolean>();

  formatResponse(responseText: string) {
    return this.markdownService.parse(responseText);
  }

  async askQueryFromBrainContent(
    query: string,
    brain: Brain | null,
    isEmbeddedChat: boolean,
    embeddedChatCreator: string,
    user?: UserData,
    brainLLM?: BrainLLM,
  ) {
    let userId: string | null = null;
    if (isEmbeddedChat) {
      userId = embeddedChatCreator;
    } else {
      if (user) {
        userId = user.id;
      }
    }

    if (!userId) {
      console.warn("No User id set");
      return;
    }

    const { latitude, longitude, address } = await this.getGeoLocation();

    const chats = AppStore.allChats$.value;
    const requestData = {
      openAIKey: "none",
      query: query,
      prompt: "yes",
      search: "no",
      provider: "openai",
      instruction: " ",
      assistantId: user?.openAIAssistantId,
      interactiveInternetSearch: brain?.interactiveInternetSearch,
      messages: chats.map(({ role, text, metadata }) => ({ role, text, metadata })),
      chatGPT: "yes",
      explain_system:
        "You are an intelligent assistant, whose primary job is to provide the most accurate and truthful answer to a question from a user. You can speak any language and ONLY respond in the same language as the question being asked. You will only answer a question if it can be determined from the context provided.",
      step_1:
        "Follow ALL the numbered steps provided. Going step by step, before providing a final answer to the user.\n\n(1) Read and understand, in depth, the below context.",
      step_2:
        // eslint-disable-next-line quotes
        '(2) Determine an answer to the below question using only the context text. Very importantly, if the answer is not contained within the context above you should say "<IDK> I\'m sorry, I don\'t know the answer to that question. Please try rephrasing your question.". Your entire response MUST be in the same language as the question below.\n- Your answer MUST never refer to the "context" or "text"',
      results: 3,
      type: "summary",
      includeValues: "false",
      includeMetadata: "true",
      pineconeIndexName: environment.pineconeIndexName,
      user: userId,
      namespace: brain?.id,
      ...(latitude !== null && { latitude }),
      ...(longitude !== null && { longitude }),
      ...(address !== null && { address }),
      ...(this.allowBrainLLMSelect && { brainLLM }),
    };

    const token = localStorage.getItem(LS_TOKEN);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const requestOptions = {
      headers: new HttpHeaders(headers),
    };

    const startTime = (new Date()).getTime();
    let endTime: number | undefined;

    const requestObservable = isEmbeddedChat
      ? this.https.post<any>(`${environment.apiBaseUrlAI}/projects/query`, requestData, requestOptions)
      : this.http.post<ChatQueryResponse>(`${environment.apiBaseUrlAI}/projects/query`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    requestObservable
      .pipe(
        mergeMap((resp) => {
          let answer = resp.answer || resp.data.answer;
          const references = this.parseReferences(resp?.data?.references);
          let metadata = resp?.metadata || resp?.data?.metadata || {};
          const assistantResponse = GetAssistantResponse(answer);
          if (assistantResponse) {
            answer = this.translocoService.translate(assistantResponse.translationKey);
          }
          this.cacheGeoLocation(metadata);

          if (this.allowBrainLLMSelect) {
            ({ metadata, endTime } = this.appendRequestLogs(metadata, startTime, endTime));
          }

          const conversation: Conversation = {
            project: isEmbeddedChat ? "" : brain?.id,
            references: references,
            role: ChatDataRole[ChatDataRole.assistant],
            text: answer as string,
            createdBy: userId,
            imageUrls: resp?.data?.imageUrls || [],
            metadata: JSON.stringify(metadata),
          } as Conversation;


          const createMessageObservable = this.conversationService.create(conversation);
          return createMessageObservable.pipe(map((createMessageResp) => ({ resp, createMessageResp })));
        }),
        catchError((error) => {
          const errorMessage = "Sorry we seem to be experiencing some problem processing your query! Please try again";
          const conversation:Conversation = {
            project: isEmbeddedChat ? "" : brain?.id,
            references: [] as string[],
            role: ChatDataRole[ChatDataRole.assistant],
            text: errorMessage,
            imageUrls: [] as string[],
            createdBy: userId,
          } as Conversation;

          const createErrorMessage$ = this.conversationService.create(conversation);
          
          return createErrorMessage$.pipe(
            switchMap((res) => {
              AppStore.allChats$.next([
                ...AppStore.allChats$.value.filter((c) => c.text),
                {
                  ...conversation,
                  id:res.data?.id
                }
              ]);
              AppStore.chatApiInProgress$.next({ brainId:res.data?.id||"", inProgress: false });
              return throwError(error);
            }),
          );
        }),
      )
      .subscribe(({ resp, createMessageResp }) => {
        const chatId = createMessageResp.data?.id as string;
        const references = this.parseReferences(resp?.data?.references);
        let metadata = resp?.metadata || resp?.data?.metadata || {};
        this.cacheGeoLocation(metadata);
        if (resp.answer || (resp.isSuccess && resp.data)) {
          const formattedResponse = this.formatResponse(resp.answer || resp.data.answer);
          let answer = resp.answer || resp.data.answer;
          const assistantResponse = GetAssistantResponse(answer);
          if (assistantResponse) {
            answer = this.translocoService.translate(assistantResponse.translationKey);
          }
          if (this.allowBrainLLMSelect) {
            ({ metadata, endTime } = this.appendRequestLogs(metadata, startTime, endTime));
          }

          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: answer,
              creationDate: new Date(),
              references: references,
              formattedContent: formattedResponse,
              isAssistantQuestion: !!assistantResponse,
              id: chatId,
              project: brain?.id,
              imageUrls: resp?.data?.imageUrls || [],
              metadata: metadata,
            } as Conversation,
          ]);
        } else {
          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              creationDate: new Date(),
              formattedContent: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              id: chatId,
              project: brain?.id,
            } as Conversation,
          ]);
        }
        if (brain) {
          AppStore.chatApiInProgress$.next({ brainId: brain?.id, inProgress: false });
        }
      });
  }

  async askQueryFromChatGPTStream(
    query: string,
    brainId: string,
    prevChats: Conversation[],
    isEmbeddedChat: boolean,
    brainLLM: BrainLLM,
  ): Promise<Conversation> {
    let stopStreamSubscription: Subscription | null = null;
    try {
      const userId = localStorage.getItem(LS_USER_ID);
      const token = localStorage.getItem(LS_TOKEN);

      const { latitude, longitude, address } = await this.getGeoLocation();

      const startTime = (new Date()).getTime();
      let endTime: number | undefined;

      const response = await fetch(`${environment.apiBaseUrlAI}/projects/ask-question-internet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          openAIKey: "none",
          query: query,
          userId,
          brainId,
          prev_data: prevChats.map(({ role, text, metadata }) => {
            return { role, text, metadata } as Partial<Conversation>;
          }),
          ...(latitude !== null && { latitude }),
          ...(longitude !== null && { longitude }),
          ...(address !== null && { address }),
          ...(this.allowBrainLLMSelect && { brainLLM }),
        }),
      });

      const reader = response?.body?.getReader();
      let answer = "";
      let references = [];
      let metadata = {};
      let canceled = false;
      if (reader) {
        const decoder = new TextDecoder();
        let buffer = "";
        stopStreamSubscription = AppStore.clearChat$.subscribe((clear) => {
          if (!clear)
            return;

          reader.cancel(); 
          canceled = true;
          AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
        });

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          let separator;
          while ((separator = buffer.indexOf("\n\n")) !== -1) {
            const message = buffer.slice(0, separator);
            buffer = buffer.slice(separator + 2);
            if (message.startsWith("event: message")) {
              const encoded_content = message.split("\ndata: ")[1];
              const decodedUtf8Text = this.decodeEncodedUtf8Text(encoded_content);
              answer += decodedUtf8Text;
              this.processSseData(decodedUtf8Text);
            } else if (message.startsWith("event: metadata")) {
              const encoded_content = message.split("\ndata: ")[1];
              metadata = JSON.parse(atob(encoded_content)); // Base64 decoding
            } else if (message.startsWith("event: stop")) {
              const encoded_content = message.split("\ndata: ")[1];
              references = JSON.parse(atob(encoded_content)); // Base64 decoding
              AppStore.internetSearchStatus$.next(InternetSearchStatus.searching);
            } else if (message.startsWith("event: status")) {
              const encoded_content = message.split("\ndata: ")[1];
              const decodedUtf8Text = this.decodeEncodedUtf8Text(encoded_content);
              AppStore.internetSearchStatus$.next(decodedUtf8Text as InternetSearchStatus);
            }
          }
        }
      }

      if (AppStore.clearChat$.value)
        return Promise.resolve({} as Conversation);

      this.cacheGeoLocation(metadata);

      if (this.allowBrainLLMSelect) {
        ({ metadata, endTime } = this.appendRequestLogs(metadata, startTime, endTime));
      }

      const conversation: Conversation = {
        role: ChatDataRole[ChatDataRole.assistant],
        text: answer as string,
        creationDate: new Date(),
        formattedContent: this.formatResponse(answer),
        project: isEmbeddedChat ? "" : brainId,
        references: references,
        createdBy: userId,
        metadata: JSON.stringify(metadata),
      } as Conversation;

      const createMessageObservable = this.conversationService.create(conversation);

      if (answer) {
        createMessageObservable.subscribe((resp) => {
          const updatedMessage = {
            ...conversation,
            id: resp.data?.id,
          } as Conversation;

          AppStore.allChats$.next([...AppStore.allChats$.value.filter((c) => c.text), updatedMessage]);
        });
      } else {
        AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
        createMessageObservable.subscribe((resp) => {
          AppStore.allChats$.next([
            ...AppStore.allChats$.value.filter((c) => c.text),
            {
              role: ChatDataRole.assistant,
              text: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              creationDate: new Date(),
              formattedContent: "Sorry we seem to be experiencing some problem processing your query! Please try again",
              id: resp.data?.id,
            } as Conversation,
          ]);
        });
      }
      return conversation;
    } catch (e) {
      const conversation = {
        role: ChatDataRole.assistant,
        text: "Sorry we seem to be experiencing some problem processing your query! Please try again",
        creationDate: new Date(),
        formattedContent: "Sorry we seem to be experiencing some problem processing your query! Please try again",
      } as Conversation;
      AppStore.chatApiInProgress$.next({ brainId: brainId, inProgress: false });
      AppStore.allChats$.next([...AppStore.allChats$.value.filter((c) => c.text), conversation]);

      return conversation;
    } finally {
      AppStore.answer$.next("");
      AppStore.answerStream$.next({ stop: true, char: "" });
      if (stopStreamSubscription){
        stopStreamSubscription.unsubscribe();
      }
      AppStore.clearChat$.next(false);
    }
  }

  decodeEncodedUtf8Text(encoded_content: string): string {
    const binaryString = atob(encoded_content);
    const decodedUtf8Text = new TextDecoder("utf-8").decode(
      new Uint8Array([...binaryString].map((char) => char.charCodeAt(0))),
    );
    return decodedUtf8Text;
  }

  processSseData(data: string) {
    for (const char of data) {
      AppStore.answerStream$.next({ stop: false, char }); // Emit each character into the subject
    }
  }

  private parseReferences(references: any[]): string[] {
    if (!Array.isArray(references)) {
      return [];
    }
    return references
      .map((ref: any) => {
        console.log("ref", ref);
        if (typeof ref === "string") {
          return ref;
        } else if (typeof ref === "object") {
          return ref.title || ref.link;
        }
        return null;
      })
      .filter(Boolean);
  }

  private async getGeoLocation() {
    let latitude = null;
    let longitude = null;
    let address = null;

    try {
      const positionPromise = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const positionData = await positionPromise;
      if (positionData) {
        latitude = (positionData as any).coords.latitude;
        longitude = (positionData as any).coords.longitude;

        const userAddress = localStorage.getItem(`${LS_USER_GEO},${latitude},${longitude}`);
        if (userAddress) {
          console.log("Using cached location");
          address = userAddress;
        }
      }
    } catch (error) {
      console.warn("User blocked location access");
      console.warn(error);
    }

    return { latitude, longitude, address };
  }

  private cacheGeoLocation(metadata: { geolocation?: string; address?: string }) {
    if (metadata.geolocation && metadata.address) {
      // metadata.geolocation is like f"{lat},{lon}"
      localStorage.setItem(`${LS_USER_GEO},${metadata.geolocation}`, metadata.address);
    }
  }

  private appendRequestLogs(metadata: any, startTime: number, endTime?: number): {
    metadata: any;
    endTime: number|undefined;
  } {
    if (endTime === undefined) {
      endTime = new Date().getTime();
    }
    const duration = (endTime - startTime) / 1000;
    metadata.requestLogs = `DEV debug: ${metadata?.brainLLM || defaultBrainLLM} took ${duration}s`;
    return { metadata, endTime };
  }
}
