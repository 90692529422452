/* eslint-disable max-lines */

import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { Conversation } from "app/api-models/conversation.models";
import { AppStore } from "app/app-store.service";
import { EmbeddingFile } from "app/app.model";
import { AppService } from "app/app.service";
import { MY_BRAIN_ROUTE } from "app/const/app-constant";
import { BrainStyle } from "app/core/components/embedded-chat/embedded-chat.model";
import {
  addAlpha,
  getFormattedBrainName,
  isBrowserChrome,
  replaceParamsWithValue,
} from "app/core/functions/helper-functions";
import {
  getMobileappSettingsInterface,
  isMobileSettingsInterfaceDefined,
} from "app/core/modules/mobile-interfaces/app-device-assistant-interface";
import {
  getMobileappMicrophoneInterface,
  isMicrophoneInterfaceDefined,
  postAppleMicrophonePermission,
} from "app/core/modules/mobile-interfaces/app-microphone-interface";
import { BrainLimitDirective } from "app/directives/brain-limit.directive";
import { environment } from "app/environments/environment";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { UserData, UserDataLimits } from "app/pages/home/home.model";
import { BrainLLM, ChatDataRole, defaultBrainLLM, VALID_BRAIN_LLMS } from "app/pages/my-brain/my-brain.model";
import { VoiceRecognitionService } from "app/services/chrome-speech.service";
import { ConversationService } from "app/services/conversation.service";
import { SocketService } from "app/services/socket.service";
import { UserDataLimitsService } from "app/services/user-data-limits.service";
import { UsersService } from "app/services/users.service";
import { filter, mergeMap, of, shareReplay, switchMap } from "rxjs";
import { AddMyBrainContentComponent } from "../add-my-brain-content/add-my-brain-content.component";
import { AddMyBrainContentService } from "../add-my-brain-content/add-my-brain-content.service";
import { BaseHttpComponent } from "../base-http/base-http.component";

@Component({
  selector: "app-ask-my-brain-search",
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    AddMyBrainContentComponent,
    MatDialogModule,
    NgFor,
    AsyncPipe,
    TranslocoModule,
    BrainLimitDirective,
    NgClass,
    NgStyle,
    FormsModule,
  ],
  templateUrl: "./ask-my-brain-search.component.html",
  styleUrls: ["./ask-my-brain-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddMyBrainContentService],
})
export class AskMyBrainSearchComponent extends BaseHttpComponent implements OnInit {
  search = new FormControl<string>("", { nonNullable: true });
  userData?: UserData;
  uploadingBrainContents: EmbeddingFile[] = [];
  brainId!: string;
  AppStore = AppStore;
  selectedBrain: Brain | null = null;
  addBrainContentInProgress = false;
  searchPlaceholder = "";
  chats: Conversation[] = AppStore.allChats$.value;
  @Input() conversationPage = false;
  @Input() activeLang = "";
  @Input() canAddContent = true;
  @Input() isEmbeddedChat = false;
  @Input() embeddedChatCreator = "";
  @Input() embeddedChatHasContent = false;
  @Input() customizeColors = false;
  @Input() customizeColorValues?: BrainStyle;
  @Input() shouldUpdateLimits = true;

  public isUserSpeaking = false;
  showCameraIcon = false;
  userDataLimits!: UserDataLimits;
  selectedLanguage!: string;
  selectedBrainLLM: BrainLLM = defaultBrainLLM;
  allowBrainLLMSelect = environment.featureFlags.allowBrainLLMSelect;
  validBrainLLMs: BrainLLM[] = VALID_BRAIN_LLMS;

  query = "";

  constructor(
    public dialog: MatDialog,
    private appService: AppService,
    private addContentService: AddMyBrainContentService,
    public translocoService: TranslocoService,
    private voiceRecognition: VoiceRecognitionService,
    private socketService: SocketService,
    private conversationService: ConversationService,
    private userLimitService: UserDataLimitsService,
    private userService: UsersService,
  ) {
    super();
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddMyBrainContentComponent, {
      disableClose: true,
      panelClass: "scrollable-dialog",
    });
    dialogRef.afterClosed().subscribe();
  }

  ngOnInit(): void {
    this.subs$.add(
      AppStore.selectedBrainId$.subscribe((id) => {
        this.brainId = id as string;
        this.cdr.markForCheck();
      }),
    );

    this.subs$.add(
      AppStore.askQuesFromSuggestedQuestion$.subscribe((ques) => {
        this.search.setValue(ques);
        this.sendClick();
      }),
    );

    this.subs$.add(
      AppStore.selectedBrain$.subscribe((brain) => {
        this.selectedBrain = brain;
        this.cdr.markForCheck();
      }),
    );

    if (this.conversationPage) {
      this.subs$.add(
        AppStore.questionAsked$.pipe(filter((ques) => !!ques)).subscribe((ques) => {
          this.search.setValue(ques);
          this.sendClick(true);
        }),
      );
    }

    this.subs$.add(
      AppStore.userData$.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.userLimitService.checkUserLimit(userData);
          this.cdr.markForCheck();
        }
      }),
    );

    this.subs$.add(
      AppStore.userDataLimits$.subscribe((resp) => {
        if (resp) {
          this.userDataLimits = resp as UserDataLimits;
          this.cdr.markForCheck();
        }
      }),
    );

    this.subs$.add(
      AppStore.selectedLanguage$.subscribe((resp) => {
        this.selectedLanguage = resp;
        this.setSearchPlaceholder(this.selectedLanguage);
      }),
    );

    this.subs$.add(
      AppStore.allChats$.pipe(shareReplay(1)).subscribe((chats) => {
        this.chats = chats;
        this.setSearchPlaceholder(this.selectedLanguage);
      }),
    );

    if (this.allowBrainLLMSelect) {
      this.subs$.add(
        AppStore.selectedBrainLLM$.subscribe((brainLLM: BrainLLM) => {
          this.selectedBrainLLM = brainLLM;
          this.cdr.markForCheck();
        }),
      );
    }

    if (isBrowserChrome()) {
      this.initVoiceInput();
    }
    this.showCameraIcon = isMobileSettingsInterfaceDefined();
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      const isChatInProgress = AppStore.chatApiInProgress$.value.inProgress;
      if (
        isChatInProgress ||
        this.addBrainContentInProgress ||
        !this.search.value.length ||
        this.userDataLimits.isQuestionExceeded
      ) {
        return;
      }
      this.sendClick();
    }
  }

  async sendClick(initial = false) {
    this.query = this.search.value;
    if (!this.query) {
      return;
    }
    this.search.setValue("");
    if (!this.conversationPage) {
      this.addBrainContentInProgress = true;
    }

    if (this.conversationPage) {
      if (!initial && !this.isEmbeddedChat) {
        const conversation: Conversation = {
          project: this.selectedBrain?.id || "",
          references: [] as string[],
          role: ChatDataRole[ChatDataRole.user],
          text: this.query,
          imageUrls: [] as string[],
          createdBy: this.userData?.id,
          creationDate: new Date(),
        } as Conversation;

        this.conversationService
          .create(conversation)
          .pipe(
            mergeMap((resp) => {
              if (resp && resp.isSuccess && resp.data) {
                return this.userService.get(this.selectedBrain?.createdBy);
              }

              return of(null);
            }),
          )
          .subscribe((resp) => {
            if (resp && resp.isSuccess && resp.data) {
              AppStore.userData$.next(resp.data);
            }
          });
      }

      const currentBrainLLM = this.allowBrainLLMSelect ? AppStore.selectedBrainLLM$.value : defaultBrainLLM;

      // if a brain have content then get answer from brain content. Otherwise use chatgpt
      if (AppStore.brainContents$.value.length || this.embeddedChatHasContent) {
        AppStore.chatApiInProgress$.next({ brainId: this.brainId, inProgress: true });
        this.cdr.markForCheck();

        // ask from brain content also for processing content because of the full web ingestion
        if (AppStore.brainContents$.value?.length || this.embeddedChatHasContent) {
          let prevChats: Conversation[] = [];
          if (!initial) {
            prevChats = AppStore.allChats$.value.slice(0);
            this.addChatMessage(prevChats);
          }
          await this.appService.askQueryFromBrainContent(
            this.query,
            this.selectedBrain,
            this.isEmbeddedChat,
            this.embeddedChatCreator,
            this.userData,
            currentBrainLLM,
          );
          return;
        }
      } else {
        AppStore.clearChat$.next(false);
        AppStore.chatApiInProgress$.next({ brainId: this.brainId, inProgress: true });
        let prevChats: Conversation[] = [];
        if (!initial) {
          prevChats = AppStore.allChats$.value.slice(0);
          this.addChatMessage(prevChats);
        }
        await this.appService.askQueryFromChatGPTStream(
          this.query,
          this.brainId,
          prevChats,
          this.isEmbeddedChat,
          currentBrainLLM,
        );
      }
    } else {
      if (!this.userData) return;
      this.addContentService
        .createBrain(this.userData, getFormattedBrainName(this.query))
        .pipe(
          switchMap((brain) => {
            if (brain) {
              this.navigateToMyBrain(brain.id);
              this.AppStore.selectedBrain$.next(brain);
              this.AppStore.selectedBrainId$.next(brain.id);
              this.AppStore.initialChat$.next(true);
              const conversation: Conversation = {
                project: brain.id || "",
                references: [] as string[],
                role: ChatDataRole[ChatDataRole.user],
                text: this.query,
                createdBy: this.userData?.id,
              } as Conversation;

              return this.conversationService.create(conversation);
            }

            return of(null);
          }),
        )
        .pipe(
          mergeMap((resp) => {
            if (resp && resp.isSuccess) {
              return this.userService.get(this.selectedBrain?.createdBy);
            }

            return of(null);
          }),
        )
        .subscribe((resp) => {
          if (resp && resp.isSuccess && resp.data) {
            AppStore.userData$.next(resp.data);
          }
        });
    }
  }

  addChatMessage(prevChats: Conversation[]) {
    const conversation: Conversation = {
      project: this.selectedBrain?.id || "",
      references: [] as string[],
      role: ChatDataRole[ChatDataRole.user],
      text: this.query,
      imageUrls: [] as string[],
      createdBy: this.userData?.id,
      creationDate: new Date(),
    } as Conversation;
    const chats = [...prevChats, conversation];

    AppStore.allChats$.next([
      ...chats,
      {
        role: ChatDataRole.assistant,
        text: "",
        creationDate: new Date(),
        formattedContent: "",
        project: this.selectedBrain?.id || "",
        createdBy: this.userData?.id,
      } as Conversation,
    ]);
  }

  navigateToMyBrain(id: string) {
    if (this.query) {
      AppStore.questionAsked$.next(this.query || "");
      AppStore.allChats$.next([
        {
          role: ChatDataRole.user,
          text: this.query,
          creationDate: new Date(),
          formattedContent: this.query,
          project: id,
          createdBy: this.userData?.id,
        } as Conversation,
        {
          role: ChatDataRole.assistant,
          text: "",
          creationDate: new Date(),
          formattedContent: "",
          project: id,
          createdBy: this.userData?.id,
        } as Conversation,
      ]);
    }
    this.router.navigate([`${replaceParamsWithValue(MY_BRAIN_ROUTE, { id })}`]);
  }

  setSearchPlaceholder(selectedLanguage: string) {
    this.translocoService.selectTranslateObject("askMyBrainSearch", {}, selectedLanguage).subscribe((result) => {
      if (this.conversationPage) {
        if (this.chats.length) {
          this.searchPlaceholder = result.askFollowUp;
        } else {
          this.searchPlaceholder = result.askQuestion;
        }
      } else {
        this.searchPlaceholder = result.searchDocumentsSite;
      }
      this.cdr.markForCheck();
    });
  }

  initVoiceInput() {
    this.voiceRecognition.init().subscribe((x) => {
      this.isUserSpeaking = false;
      this.cdr.markForCheck();
    });
    this.voiceRecognition.speechInput().subscribe((input) => {
      this.search.setValue(input);
    });
  }

  startRecording() {
    this.isUserSpeaking = true;
    this.voiceRecognition.start(AppStore.selectedLanguage$.value || "en-US");
    this.notifyMobileAppForMicPermission();
  }

  stopRecording() {
    this.voiceRecognition.stop();
    this.isUserSpeaking = false;
    this.cdr.markForCheck();
  }

  switchRecording() {
    const isChrome = isBrowserChrome();
    if (isChrome) {
      this.chromeSpeechToTextRecording();
    } else {
      this.apiSpeechToTextRecording();
      postAppleMicrophonePermission();
    }
  }

  chromeSpeechToTextRecording() {
    if (!this.isUserSpeaking) {
      this.startRecording();
    } else {
      this.stopRecording();
    }
  }

  apiSpeechToTextRecording() {
    if (!this.isUserSpeaking) {
      this.isUserSpeaking = true;
      console.log("Started recording...");
      this.socketService.connectSocket();
      this.socketService.stopRecording();
      this.socketService.initRecording(
        this.socketService.getTranscriptionConfig(),
        (data: string, isFinal: boolean) => this.handleDataReceived(data, isFinal),
        (error: unknown) => {
          console.log(error);
          this.isUserSpeaking = false;
          this.cdr.markForCheck();
          this.socketService.stopRecording();
        },
      );
    } else {
      this.socketService.stopRecording();
      this.isUserSpeaking = false;
      this.cdr.markForCheck();
    }
  }

  handleDataReceived(data: string, isFinal: boolean) {
    // Only insert the text into the search field when final transcript is received
    if (isFinal) {
      this.search.setValue(data);
      this.isUserSpeaking = false;
      this.socketService.stopRecording();
      this.cdr.markForCheck();
    }
  }

  notifyMobileAppForMicPermission() {
    if (isMicrophoneInterfaceDefined()) {
      getMobileappMicrophoneInterface().notifyMobileAppForMicPermission();
    }
  }

  openCamera() {
    this.openMobileAppNativeCamera();
  }

  openMobileAppNativeCamera() {
    if (isMobileSettingsInterfaceDefined()) {
      getMobileappSettingsInterface().onCameraOpen();
    }
  }

  onBrainLLMChange(event: Event) {
    const select = event.target as HTMLSelectElement;
    const value = select.value as BrainLLM;
    console.log(value);
    AppStore.selectedBrainLLM$.next(value as BrainLLM);
  }
  protected readonly addAlpha = addAlpha;
}
